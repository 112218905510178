import axios from 'axios';
import React, {  useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import '../Customcss/table.css'


import Swal from 'sweetalert2';

import Puff from 'react-loading-icons/dist/esm/components/puff';
import { Row } from 'react-bootstrap';

function Store() {
const [isClick,setIsClick]=useState(false);
    const [name,setName]=useState({

        name:'',
        
    });
    const [data,setData]=useState([]);
    const [currentPage, setCurrentPage] = useState(1);
  
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [indexit,setIndexIt]=useState('');
    const handleInput=(e)=>{

e.preventDefault();
setName({...name,[e.target.name]:e.target.value});

    }
const submitCategory=(e)=>{
  setIsClick(true);
e.preventDefault();
const data={
name:name.name

}
axios.get('/sanctum/csrf-cookie').then(res=>{
axios.post('api/post/add/store',data).then(res=>{

    Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: res.data.message,
        showConfirmButton: false,
        timer: 1500
      })
      

setIsClick(false);
})

})


}


  
    useEffect(()=>{
      axios.get(`/api/view/store?page=${currentPage}&search=${searchQuery}`).then(response=>{
        setData(response.data.category.data);
        setTotalPages(response.data.category.last_page);
      })
     
      
    
    },[currentPage,searchQuery,isClick]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
      };
const handleDelete=(e,id)=>{

 setIsClick(true);

  
axios.delete(`api/delete/store/${id}`).then(res=>{

if(res.data.status===200){
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: res.data.message,
    showConfirmButton: false,
    timer: 1500
  })
  
setIsClick(false);
}else{


}

})

}
const [editCategory,setEditCategory]=useState('');
const handleEdit=(e,id,index)=>{
setIndexIt(index);
setEditCategory(id);

axios.get(`api/edit/store/${id} `).then(response=>{

  setName({...name,'name':response.data.category.name});
  setIndexIt('');
})


}

const submitUpdate=(e)=>{
setIsClick(true);

e.preventDefault();
const data={
name:name.name,
id:editCategory

}

axios.get('/sanctum/csrf-cookie').then(res=>{
axios.put('api/update/store',data).then(res=>{
if(res.data.status===200){
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: res.data.message,
    showConfirmButton: false,
    timer: 1500
  })
  
  setIsClick(false);

}else{
  setIsClick(false);

  Swal.fire({
    position: 'top-end',
    icon: 'warning',
    title: 'something went wrong',
    showConfirmButton: false,
    timer: 1500
  })
}


})

})

}

  return (
    <div className="row fadeInDown animated" >

    
    {editCategory?
    <center>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>Edit Store</h2> <hr/>
            </div>   
       
    <div className='col-md-10 mt-4'>
    <FloatingLabel controlId="storename" label="Edit Store Name">
        <Form.Control type="text" name="name" placeholder="Edit Store Name" value={name.name} onChange={handleInput} />
      </FloatingLabel>
     
    </div>
    <div className='col-md-10 mt-4'>

    <Row className='g-2 mt-4'>

<div className='col md'>
<button onClick={submitUpdate} className='btn btn-success btn-lg  btn-block'>{isClick?  <Puff height={25} width={30}/>:'Update'}  </button>

</div>
<div className='col md'>
<button onClick={(e)=>setEditCategory('')} className='btn btn-info btn-lg btn-block'>Add New  </button>

</div>
    </Row></div>
    </div></center>: 
    
    
    
    <center>


        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>Add Store</h2><hr></hr>
            </div>   
       
    <div className='col-md-10 mt-4'>
    <FloatingLabel controlId="storename" label="Store Name">
        <Form.Control type="text" name="name" placeholder="Enter Store Name" value={name.name} onChange={handleInput} />
      </FloatingLabel>
    </div>
    <div className='col-md-10 mt-4'>
<button onClick={submitCategory} className='btn btn-success btn-lg btn-block'>{isClick?  <Puff height={25} width={30}/>:'Save'}  </button>
    </div>
    </div></center>}
<div className='container-fluid'  >
<div className='row' style={{padding:'30px'}}>

<div className='col-md-12' style={{ height: 'auto', overflow: 'auto',border:'1px solid lightgray  ',padding:'15px'}}>
<input type="text"  placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />
    
    <Table striped bordered hover size="sm" >
    <thead>
        <tr>
        <th>Sr</th>
        <th>Name</th>
        <th>Delete</th>
            <th>Update</th>
        </tr>
    </thead>
  <tbody>
 {data && data.map((item,index)=>(
 <tr key={item.id} 
 >

    <td>{item.id}</td>
    <td >{item.name}</td>
<td><button className='btn btn-danger' onClick={(e) => handleDelete(e,item.id)} style={{marginRight:'3px'}}><FaTrash style={{marginBottom:'3px'}}/></button> </td>
        
        <td>
        <button onClick={(e)=>handleEdit(e,item.id,index)} className='btn btn-success '> {indexit===index?<Puff height={10} width={12}/>: <FaEdit style={{marginBottom:'3px'}}/>}</button> 
        </td>
      
       </tr>
      
 ))
    
}</tbody>  

</Table>
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>
</div></div></div>

    </div>
  )
}

export default Store
