import { createContext, useContext, useState } from "react";

const StateContext=createContext({
user:null,
token:null,
items:[],
setItems:()=>{},
type:null,
setType:()=>{},
setUser:()=>{},
setToken:()=>{},
userId:null,
setUserId:()=>{},
})

export const ContextProvider=({children})=>{
const [user,_setUser]=useState(localStorage.getItem('AUTH_NAME'));
const [token,_setToken]=useState(localStorage.getItem('ACCESS_TOKEN'));
const [type,_setType]=useState(localStorage.getItem('USER_TYPE'));
const [items,_setItems]=useState(localStorage.getItem('Cart'));
const [userId,_setUserId]=useState(localStorage.getItem('User_id'));


const setToken=(token)=>{

_setToken(token);

if(token){
localStorage.setItem('ACCESS_TOKEN',token);

}else{
localStorage.removeItem('ACCESS_TOKEN');

}

}
const setUserId=(userId)=>{

    _setUserId(userId);
    
    if(userId){
    localStorage.setItem('User_id',userId);
    
    }else{
    localStorage.removeItem('User_id');
    
    }
    
    }
const setUser=(user)=>{

    _setUser(user);
    
    if(user){
    localStorage.setItem('AUTH_NAME',user);
    
    }else{
    localStorage.removeItem('AUTH_NAME');
    
    }
    
    }

    const setItems=(items)=>{
        const usersString = JSON.stringify(items);
        
        _setItems(usersString);
        if(items){
            localStorage.setItem('Cart', usersString);
            
            }else{
            localStorage.removeItem('Cart');
            
            }
        
        }
    const setType=(type)=>{

        _setType(type);
        
        if(type){
        localStorage.setItem('USER_TYPE',type);
        
        }else{
        localStorage.removeItem('USER_TYPE');
        
        }
        
        }

return(

<StateContext.Provider value={{
user,
token
,
setUser,
setToken,
type,
setType,
items,
setItems,
userId,
setUserId,




}}>

{children}
</StateContext.Provider>



)



}


export const useStateContext=()=>useContext(StateContext);