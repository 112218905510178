
import React, {  useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Swal from 'sweetalert2';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import '../Customcss/table.css'

import Puff from 'react-loading-icons/dist/esm/components/puff';
import { useStateContext } from '../Context/ContextProvider';

function Users() {
 const{type}= useStateContext();
  const [isClick,setIsClick]=useState(false);
  const [isThisClick,setIsThisClick]=useState('');


    const [currentPage, setCurrentPage] = useState(1);
  
    const [totalPages, setTotalPages] = useState(1);
    const [users,setUsers]=useState([]);
const [searchQuery,setSearchQuery]=useState('');
const [userid,setUserId]=useState('');


const handleSearchChange = (event) => {

  setSearchQuery(event.target.value);
  setCurrentPage(1);
};



useEffect(()=>{
 
  axios.get(`/api/view/userslist?page=${currentPage}&search=${searchQuery}`).then(response=>{
    setUsers(response.data.users.data);
    setTotalPages(response.data.users.last_page);
  })
 
  

},[currentPage,searchQuery,isThisClick,isClick]);







  


    const [inputUser,setInputUser]=useState({
     
        name:'',
        email:'',
        phone:'',
        cnic:'',
     gender:'',
       type:'Customer',
      
        password:'',
        address:'',
     
        errorlist:[],
      
     
        


        
    });

 
  

    const handleInput=(e)=>{
        
e.preventDefault();


setInputUser({...inputUser,[e.target.name]:e.target.value});
    }





  
    

const handleSubmit=(e)=>{
  setIsThisClick(true);
    e.preventDefault();


const data={...inputUser};


axios.post('api/add/user',data).then(res=>{

if(res.data.status===200){
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: res.data.message,
    showConfirmButton: false,
    timer: 1500
  })
  setIsThisClick(false);
}else{
setInputUser({...inputUser,errorlist:res.data.validation_errors});
setIsClick(false);
setIsThisClick(false);

}

})


}

const handleDelete=(e,id,index)=>{
setIsThisClick(index);
axios.delete(`api/delete/user/${id}`).then(res=>{
if(res.data.status===200){
  Swal.fire('success',res.data.message);
setIsThisClick('');
}else{

}

})


}
const handleEdit=(e,id,index)=>{
setIsThisClick(index);
setUserId(id);
axios.get(`api/edit/user/${id}`).then(res=>{


const updateitems={
 ...inputUser,
  'name':res.data.user.name===null?'':res.data.user.name,
  'email':res.data.user.email===null?'':res.data.user.email,
  'phone':res.data.user.phone===null?'':res.data.user.phone,
  'cnic':res.data.user.cnic===null?'':res.data.user.cnic,
'gender':res.data.user.gender===null?'':res.data.user.gender,
 'type':res.data.user.type===null?'':res.data.user.type,


  'address':res.data.user.city===null?'':res.data.user.city,
  

}


setInputUser(updateitems);








setIsThisClick('');
//setInputProduct({...inputProduct,})


});



}

const handleUpdate=(e)=>{
  setIsClick(true);
e.preventDefault();
const userids={
  'userid':userid
}
  const data={...inputUser,...userids};
  
  axios.post('api/update/user',data).then(res=>{
if(res.data.status===401){
setInputUser({...inputUser,errorlist:res.data.validation_errors});
}else{
  Swal.fire('success','updated successfully');

}
setIsClick(false);

  })
}
const handleWhatsapp=(phoneNumber)=>{
  const message = 'Hello,Remainder From Company Of Payment!';
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
 //const url = `whatsapp://send?phone=${encodeURIComponent(phoneNumber)}&text=${encodeURIComponent(message)}`; 
 window.open(url, '_blank');

}

const handleExportPdf=()=>{
 setIsClick(true);

  axios({
    url: 'api/users/export/pdf',
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    window.open(url, '_blank');
    
   
   setIsClick(false);
  });



}

  return (
    <div className='animated fadeInDown'>
      {userid?
      <center>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px',marginBottom:'10px'}}>

        <div className='co-md-11'>
<h2>EDIT USER</h2><hr></hr>
            </div>   
            <Row className="g-2">
    <div className='col-md mt-4'>
    <FloatingLabel  label="User Name" >
        <Form.Control type="text"  name="name" placeholder="User Name" value={inputUser.name} onChange={(e)=>handleInput(e)} />
        <span style={{color:"red"}}>{inputUser.errorlist.name}</span>
      </FloatingLabel>
      
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="User Phone" >
        <Form.Control type="text"  name="phone" placeholder="User Phone" value={inputUser.phone} onChange={(e)=>handleInput(e)} />
        <span style={{color:"red"}}>{inputUser.errorlist.phone}</span>
    
      </FloatingLabel>
      
     
    </div></Row>
    <Row className="g-2">

    <div className='col-md mt-4'>
    <FloatingLabel  label="User Email" >
        <Form.Control type="email" name="email" placeholder="User Email" value={inputUser.email} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
    <div className='col-md mt-4'>
    <FloatingLabel  label="Cnic" >
        <Form.Control type="text"  name="cnic" placeholder='user cnic'  value={inputUser.cnic} onChange={(e)=>handleInput(e)}  />
      </FloatingLabel>
     
    </div></Row>
    
   
   
 
    <Row className="g-2">

    <div className='col-md mt-4'>
    <FloatingLabel  label="User Type">
      <Form.Select aria-label="User Type"  value={inputUser.type} name='type' onChange={(e)=>handleInput(e)}>
        <option selected value="Customer">Customer</option>
        <option value="Salesman">Salesman</option>
        <option value="Distributer">Distributer</option>
      
      </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md mt-4'>
<FloatingLabel  label="User Gender">
<Form.Select aria-label="User Gender"  value={inputUser.gender} name='gender' onChange={(e)=>handleInput(e)}>
<option value="Male">Male</option>
<option value="Female">Female</option>


</Form.Select>
</FloatingLabel>
</div>
   </Row>
   
   <Row className="g-2">

   <div className='col-md mt-4'>
    <FloatingLabel  label="User Password" >
        <Form.Control type="password" name="password" placeholder="User Password" value={inputUser.password} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
<div className='col-md mt-4'>
<FloatingLabel  label="Address" >
<Form.Control type="text"  name="address" placeholder='user address'  value={inputUser.address} onChange={(e)=>handleInput(e)}  />
</FloatingLabel>

</div>

</Row>
<div className='col-md mt-4'>
<button  className='btn btn-success btn-lg btn-block' onClick={(e)=>handleUpdate(e)}>{isClick? <Puff height={23} stroke='yellow' strokeOpacity={1.2}/>:'Update'}  </button>

    </div>
    </div>
   
    
    </center>
    
    
    :  <center>


<div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

<div className='co-md-11'>
<h2>ADD USER</h2><hr></hr>
    </div>   
    <Row className="g-2">
<div className='col-md mt-4'>
<FloatingLabel  label="User Name" >
<Form.Control type="text"  name="name" placeholder="User Name" value={inputUser.name} onChange={(e)=>handleInput(e)} />
<span style={{color:"red"}}>{inputUser.errorlist.name}</span>
</FloatingLabel>

</div>
<div className='col-md mt-4'>
<FloatingLabel  label="User Phone" >
<Form.Control type="text"  name="phone" placeholder="User Phone" value={inputUser.phone} onChange={(e)=>handleInput(e)} />
<span style={{color:"red"}}>{inputUser.errorlist.phone}</span>

</FloatingLabel>


</div></Row>
<Row className="g-2">

<div className='col-md mt-4'>
<FloatingLabel  label="User Email" >
<Form.Control type="email" name="email" placeholder="User Email" value={inputUser.email} onChange={(e)=>handleInput(e)} />
<span style={{color:"red"}}>{inputUser.errorlist.email}</span>

</FloatingLabel>

</div>
<div className='col-md mt-4'>
<FloatingLabel  label="Cnic" >
<Form.Control type="text"  name="cnic" placeholder='user cnic'  value={inputUser.cnic} onChange={(e)=>handleInput(e)}  />

</FloatingLabel>

</div></Row>




<Row className="g-2">

<div className='col-md mt-4'>
<FloatingLabel  label="User Type">
<Form.Select aria-label="User Type"  value={inputUser.type} name='type' onChange={(e)=>handleInput(e)}>
<option value="Customer">Customer</option>
<option value="Salesman">Salesman</option>
<option value="Distributer">Distributer</option>

</Form.Select>
</FloatingLabel>
</div>
<div className='col-md mt-4'>
<FloatingLabel  label="User Gender">
<Form.Select aria-label="User Gender"  value={inputUser.gender} name='gender' onChange={(e)=>handleInput(e)}>
<option value="Male">Male</option>
<option value="Female">Female</option>


</Form.Select>
</FloatingLabel>
</div>
</Row>


<Row className="g-2">
<div className='col-md mt-4'>
    <FloatingLabel  label="User Password" >
        <Form.Control type="password" name="password" placeholder="User Password" value={inputUser.password} onChange={(e)=>handleInput(e)} />
      </FloatingLabel>
     
    </div>
<div className='col-md mt-4'>
<FloatingLabel  label="Address" >
<Form.Control type="text"  name="address" placeholder='user address'  value={inputUser.address} onChange={(e)=>handleInput(e)}  />
</FloatingLabel>

</div>

</Row>
<div className='col-md mt-4'>
<button  className='btn btn-success btn-lg btn-block' onClick={(e)=>handleSubmit(e)}>{isThisClick? <Puff height={23} stroke='yellow' strokeOpacity={1.2}/>:'Save'}  </button>

    </div>
</div>


        </center>}

<Row className='g-1'>

<div className='col-md' style={{ height: 'auto', overflow: 'auto',border:'1px solid lightgray  ',padding:'15px'}}>

<Row className='g-2'>
  <div className='col-md-4 mb-1'>
  <input type="text" className='form-control col-md' placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />

  </div>

  <div className='col-md-3'>
  <button onClick={handleExportPdf} className='btn  btn-success ml-1'>{isClick?<Puff height={10}/>:'PDF'}  </button>

  </div>



  
    
</Row>

    
    
    <Table striped bordered hover size="sm" >
    <thead>
        <tr>
        <th>Sr</th>
        <th>Name</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Gender</th>
        <th>Type</th>
        <th>Cnic</th>
        <th>Address</th>
       
        <th>Delete</th>
        <th>Update</th>
            <th>whatsapp</th>
        </tr>
    </thead>
  <tbody>
 {users && users.map((item,index)=>(


 <tr key={item.id}  style={{backgroundColor:item.type==='Salesman'?'lightpink':item.type==='Distributer'?'red':'white'}}
 >

    <td>{index+1}</td>
    <td >{item.name}</td>
    <td >{item.phone}</td>
    <td >{item.email}</td>
   
    <td >{item.gender}</td>
 
    <td >{item.type}</td>
    <td >{item.cnic}</td>

    <td >{item.address}</td>
  
<td><button className='btn btn-danger' disabled={type!=='Managment'?true:false} onClick={(e) => handleDelete(e,item.id,index)} style={{marginRight:'3px'}}>{isThisClick===index?<Puff height={10} width={15}/> :<FaTrash style={{marginBottom:'3px'}}/>}</button> </td>
        
        <td>
        <button onClick={(e)=>handleEdit(e,item.id,index)} className='btn btn-success '> {isThisClick===index?<Puff height={10} width={15}/>: <FaEdit style={{marginBottom:'3px'}}/>}</button> 
        </td>
    <td ><button className='btn btn-primary' onClick={(e)=>handleWhatsapp(item.phone)}>Chat</button> </td>
      
       </tr>
      
 ))
    
}</tbody>  

</Table>
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>
</div>

</Row>

    </div>
  )
}

export default Users
