import { Autocomplete, Box, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form, Row, Table } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import Swal from 'sweetalert2';
import {  FaTrash } from 'react-icons/fa';
function SettingInvoice() {
    const [isClick,setIsClick]=useState(false);
    const [productid,setProductId]=useState(null);
    const [results,setResults]=useState([]);
    const [invoicename,setInvoiceName]=useState([]);
    const [defaultinvoicename,setDefaultInvoiceName]=useState(0);
    const [unitsaleprice,setTotalUnitSalePrice]=useState('');
    const [data,setData]=useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [indexit, setIndexit] = useState('');
  
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
    useEffect(()=>{

        axios.get(`/api/fetch/stock/for/purchase/entry`).then(res=>{
            setResults(res.data.products);
        
             
          });
      
      },[])

      useEffect(()=>{

        axios.get(`/api/fetch/stock/for/purchase/entry`).then(res=>{
            setResults(res.data.products);
        
             
          });

          axios.get('/sanctum/csrf-cookie').then(response => {
            axios.get(`/api/getautoinvoicesnames`).then(res=>{
              setInvoiceName(res.data.names);
              setDefaultInvoiceName(res.data.names[0]['id']);
          
            });
            
            
              });
      
      },[])


      const handleSubmit=()=>{
        setIsClick(true);
const data={

    productid:productid.id,
    nameid:defaultinvoicename,
    unitsaleprice:unitsaleprice

}



axios.post('api/settingdata',data).then(res=>{

if(res.status===200){

    Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: res.data.message,
        showConfirmButton: false,
        timer: 1500
      })
      setIsClick(false);
}else{
    alert('something went wrong');
    setIsClick(false);
}

})

setProductId(null);

}

useEffect(()=>{
    axios.get(`/api/settingdatatable?page=${currentPage}&search=${searchQuery}`).then(response=>{
     

setData(response.data.data);

      setTotalPages(response.data.last_page);
    })
   
    
  
  },[currentPage,searchQuery,isClick,indexit]);
const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  }


const handleDelete=(e,id,index)=>{
setIndexit(index);
axios.delete(`api/detleteautosetting/${id}`).then(res=>{

    Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Deleted Successfully',
        showConfirmButton: false,
        timer: 1500
      })
setIndexit('');

})

    
}


  return (
    <div className='fadeInDown animated'>
       <center>


        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>SETTING INVOICE NAME</h2> <hr/>
            </div>   
            <div className='col-md-10 mt-3'>
<FloatingLabel  label="Select Invoice Name">
      <Form.Select required value={defaultinvoicename} onChange={(e)=>setDefaultInvoiceName(e.target.value)} aria-label="Select Invoice Name"  >
     <option>Select Name</option>
      {invoicename && invoicename.map(item => (
       
        <option key={item.id} value={item.id}>
          {item.name} </option>))}
       </Form.Select>
    </FloatingLabel>
</div>    
<div className='col-md-10 mt-4'>
<Autocomplete
  disablePortal
  id="combo-box-demo"
  
  value={productid}
  onChange={(event, newValue) => {
    setProductId(newValue);


  }}
  
  getOptionLabel={(results) => `${results.name}`}
  isOptionEqualToValue={(option, value) => option.name === value.name}
  options={results}

  noOptionsText={"No Item Found"}
  renderOption={(props,results)=>(
<Box component="li" {...props} key={results.id}>
{results.name} ---{results.unitsaleprice}---{results.storename}
</Box>

  )}
  renderInput={(params) => (
    <TextField     {...params} label={productid?<Puff stroke='red'/>:'Search Item'} variant="outlined" className='form-control'  />
  )}
 
  
/>
</div>

    <div className='col-md-10 mt-4'>
    <FloatingLabel controlId="saleprice" label="Set Sale Price">
        <Form.Control type="text" required value={unitsaleprice} onChange={(e)=>setTotalUnitSalePrice(e.target.value)} name="saleprice" placeholder="Sale Price" />
      </FloatingLabel>
     
    </div>
    <div className='col-md-10 mt-4'>

    <Row className='g-2 mt-4'>

<div className='col md'>
<button onClick={handleSubmit}  className='btn btn-success btn-lg  btn-block'>{isClick?  <Puff height={25} width={30}/>:'Save Data'}  </button>

</div>

    </Row></div>
    </div></center>
    <div className='container-fluid'  >
<div className='row' style={{padding:'30px'}}>

<div className='col-md-12' style={{ height: 'auto', overflow: 'auto',border:'1px solid lightgray  ',padding:'15px'}}>
<input type="text"  placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />
    
    <Table striped bordered hover size="sm" >
    <thead>
        <tr>
        <th>Sr</th>
        <th>Invoice Name</th>
        <th>Product Name</th>
        <th>Pack Qty</th>
        <th>Pack Size</th>
        <th>Qty</th>
        <th>New UnitSalePrice</th>
        <th>UnitCostPrice</th>
        <th>Unit</th>
        <th>Delete</th>
         
        </tr>
    </thead>
  <tbody>
 {data && data.map((item,index)=>(
 <tr key={item.id} 
 >

    <td>{index+1}</td>
    <td >{item.autoname}</td>
    <td >{item.name}</td>
    <td >{item.pack_qty}</td>
    <td >{item.pack_size}</td>
    <td >{item.qty}</td>
    <td >{item.unitsaleprice}</td>
    <td >{item.unitcostprice}</td>
    <td >{item.unit}</td>
    
<td><button className='btn btn-danger' onClick={(e) => handleDelete(e,item.id,index)} style={{marginRight:'3px'}}>{indexit===index?<Puff height={10} width={12}/>: <FaTrash style={{marginBottom:'3px'}}/>} </button> </td>
        
        
      
       </tr>
      
 ))
    
}</tbody>  

</Table>
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>
</div></div></div>

    </div>
  )
}

export default SettingInvoice


