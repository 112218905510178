
import {React, useEffect, useState}  from 'react';

import {  FaShoppingCart, FaStoreAltSlash } from "react-icons/fa";

import { Link } from 'react-router-dom';
import  './Customcss/home.css';

import axios from 'axios';

import CustomerChart from './Charts/CustomerChart';
import ProductChart from './Charts/ProductChart';
import MonthlyChart from './Charts/MonthlyChart';
import WeeklyChart from './Charts/WeeklyChart';
import { Row } from 'react-bootstrap';

import BallTriangle from 'react-loading-icons/dist/esm/components/ball-triangle';
import { useStateContext } from './Context/ContextProvider';


function Dashboard() {
  const [Stores ,setStores]=useState([]);
  const [topCustomers ,settopCustomers]=useState([]);
  const [monthwise ,setmonthwise]=useState([]);
  const [productsdata ,setproductsdata]=useState([]);
  const [weeklysale ,setweeklysale]=useState([]);
 
  const [Loading,setLoading]=useState(true);
  const {type}=useStateContext();
useEffect(()=>{
  axios.get('/sanctum/csrf-cookie').then(response => {
axios.get('/api/home').then(res=>{
  setStores(res.data.stores);
setmonthwise(res.data.monthwise);
settopCustomers(res.data.customerwise);
setproductsdata(res.data.productsdata);
setweeklysale(res.data.weeklysale);
setLoading(false);
});


  });


},[]);

useEffect(()=>{
       
 
  
  



},[]);

if(type==='Customer'){

return(
<div className='categories row animated fadeInDown' >

{
Stores.length===0?<center>Please Wait....</center>:
Stores && Stores.map((item,index)=>(
 <div className='boxes col-md-4  animated fadeInDown' key={item.id}>
 <Link to={`/productdetail/${item.id}`}  style={{textDecoration:'none'}}>
  <center>
<FaStoreAltSlash style={{fontSize:'45px'}}/></center>
<hr/>
<p style={{color:'red'}}>{(item.name).slice(0,12)}</p>
 

</Link></div>
))

}


</div>

)

}
  
  
return(

<>



<div style={{overflow:'hidden'}} className="fadeInDown animated">
<Row className='g-1' style={{margin:'8px'}}>
     {Stores.map(store=>(
     
<div className='col-md shadow' key={store.id}>
<Link to={`/saleentry/${store.id}`} key={store.id} style={{textDecoration:'none',color:'red',textAlign:'center'}}>
  
  {store.name}

 <br/>
  <FaShoppingCart/>
  </Link>
  </div>
  ))} 

  </Row> 
  {Loading?  <div style={{height:'100vh',width:'100vw'}}> <div style={{position: 'fixed',height:'100vh',
  width:'100vh',  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rba(255,255,255,5.8)',top:'50%',left:'50%',textAlign:'center', justifyContent: 'center',alignItems:'center'}}><BallTriangle  style={{marginTop:'45%'}}  stroke='red'  /> </div></div>
  
  :
<div className='row shadowgraph fadeInDown animated' >
  <Row className='g-2'>
<div className='col-md'>
  <WeeklyChart datas={weeklysale}></WeeklyChart>

  </div>
  <div className='col-md'>
 <CustomerChart datas={topCustomers}></CustomerChart>

  </div></Row>
  <Row className='g-2'>
  <div className='col-md'>
  <MonthlyChart data={monthwise}></MonthlyChart>

  </div>
 
  <div className='col-md'>
  <ProductChart data={productsdata}></ProductChart>

  </div>
  </Row>
</div>}
</div>

</>

     
)
};

export default Dashboard;

