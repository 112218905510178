import React from 'react'

function PrintSaleInvoice(props) {
  const styles = {
    container: {
      textAlign: 'right',
      marginRight: '10px',
      marginBottom: '4px',
      padding: '10px',
    },
    centered: {
     
      textAlign: 'center',
      fontSize: '12px',
      fontFamily: 'Times New Roman',
      border: '1px solid gray',
    },
    h1: {
      display: 'inline-block',
      fontSize: '12px',
      fontFamily: 'Times New Roman',
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
    },
    th: {
      border: '1px solid black',
     
      fontSize: '13px',
      fontFamily: 'Times New Roman',
    },
  
   
   
  };
  
 const pos=localStorage.getItem('pos') || '0';;
 
  
  const total = props.data.letter.reduce((acc, item) => {
    const value = parseFloat(item.qty*item.unitsaleprice);
    return isNaN(value) ? acc : acc + value;
  }, 0);
  const subtotaldiscount = props.data.letter.reduce((acc, item) => {
    const value = parseFloat(item.subtotaldiscount);
    return isNaN(value) ? acc : acc + value;
  }, 0);
    const totalqty = props.data.letter.reduce((acc, item) => {
      const value = parseFloat(item.qty);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    const totalweight = props.data.letter.reduce((acc, item) => {
      const value = parseFloat(item.qty*item.weight);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    const totalpack = props.data.letter.reduce((acc, item) => {
      const value = parseFloat(item.qty/item.pack_size);
      return isNaN(value) ? acc : acc + value;
    }, 0);
    const totalrate = props.data.letter.reduce((acc, item) => {
      const value = parseFloat(item.unitsaleprice);
      return isNaN(value) ? acc : acc + value;
    }, 0);
 
  if(pos==='0')
  {
    return(

<div id='printablearea'>
<div style={styles.container}>
        <center>
 <img style={{height:'80px',width:'80px'}} src={`https://hassanbookdepo.com/softwareapis/public/images/${
 props.data.detail.logo}`} alt="sory"/>
      </center>
      <div style={{textAlign:'center'}}>
        <h2 style={{ textAlign: 'center' ,marginBottom:'-10px'}} >
        {props.data.detail.slogan_heading} 
        </h2>
        <p  style={{ textAlign: 'center',marginBottom:'-10px' }}>{props.data.detail.slogan_subheading}</p>
        <p style={{textAlign:'center'}}>
          Invoice No=<span >{props.data.invoiceno}</span><br></br> Time=<span >{props.data.manualdate}</span>
        </p>
      </div>
      <table style={{...styles.table,marginTop:'-8px'}}>
        <thead>
          <tr>
          <th style={{...styles.th}}>Sr</th>
          <th style={{...styles.th}}>Item</th>
            <th style={{...styles.th}}>Qty</th>
          
            <th style={{...styles.th}}>Rate</th>
            <th style={{...styles.th}}>Total</th>
          </tr>
        </thead>
        <tbody id="printcartfortsp100">
        
        {props.data.letter && props.data.letter.map((item, index)=> (
        
        <tr key={index}>
         
                 <td style={{...styles.centered}}>  {++index}</td>
                        <td  style={{...styles.centered}}>{item.name}</td>

                        <td style={{...styles.centered}}>{item.qty}</td>

              
                          
                          <td  style={{...styles.centered}}>{item.unitsaleprice}</td>
                    
                        <td  style={{...styles.centered}}>{item.qty*item.unitsaleprice}</td>
                       
                    </tr>
))}

       
        </tbody>
      </table>
     
      <div style={{textAlign: 'left',marginTop:'4px'}}>
      <b>Total Bill: <span >{parseFloat(props.data.grandtotal).toFixed(2)}</span></b>
 
    </div>
    <div style={{textAlign: 'left'}}>
      <b> Discount: <span >{parseFloat(props.data.discount).toFixed(2)}</span></b>
      
    </div>

     <div style={{textAlign: 'left'}}>
      <b> Remaning Bill: <span >{parseFloat(props.data.remainingbill).toFixed(2)}</span></b>
      
    </div>
    <div style={{textAlign: 'left'}}>
      <b>Sales Tax: <span>{parseFloat(props.data.salestax).toFixed(2)}</span></b>
        
    </div>
    <div style={{textAlign: 'left'}}>
      <b>Shipping: <span>{parseFloat(props.data.shipping).toFixed(2)}</span></b>
        
    </div>
    <div style={{textAlign: 'left'}}>
      <b>Pre Balance: <span>{parseFloat(props.data.pbalance).toFixed(2)}</span></b>
        
    </div>
     
     <div style={{textAlign: 'left'}}>
      <b>      Payment: <span >{parseFloat(props.data.recovery)+parseFloat(props.data.payment)}</span></b>

    </div>
   
    


      
     <div style={{textAlign: 'left'}}>
      <b> Curent Bills: <span style={{fontSize:'35px'}}>{parseFloat((parseFloat(props.data.grandtotal)-parseFloat(props.data.discount)+parseFloat(props.data.salestax)-(parseFloat(props.data.payment)+parseFloat(props.data.recovery))+parseFloat(props.data.pbalance)+parseFloat(props.data.shipping))).toFixed(2)}</span></b>
       
    </div>
    
        
       
    
      <p >Thanks for your purchase!<br />Powered By: Developing Desk</p>
    </div>
</div>
    )
  }else{

  

  return (
  <div id='printablearea'>
<div  style={{float: 'center',backgroundColor: 'white',borderTop: '1px solid black',display: 'flex',justifyContent: 'space-between',marginTop: '-10px'}}>
  <div>
    <p>Contact Us:<span ><br></br>0305-6413000<br></br>0309-6413000</span></p>
     <p>Printting Date:<span >{props.data.dates}</span> </p>
</div>

   
   <div>
     <h1 style={{marginBottom: '-1px'}} >{props.data.detail.slogan_heading} </h1>
     <center><b><span style={{textDecoration: 'underline'}}> {props.data.detail.slogan_subheading}</span></b></center>
   
    
   </div>

    <div style={{textAlign:'right',marginRight: '10px',marginBottom:'4px',padding: '10px'}}>
   
 <img style={{height:'80px',width:'80px'}} src={`https://hassanbookdepo.com/softwareapis/public/images/${
 props.data.detail.logo}`} alt="sory"/>
 
   </div>
   
</div>

<div style={{display: 'flex',justifyContent: 'space-between',paddingLeft: '0px',paddingRight: '0px'}}>

  <div style={{border:'1px solid black',borderRadius: '8px',height:'80px',padding: '10px',width: '350px'}}>
    <div style={{marginBottom: '8px'}}>
     <b>Name</b>:<span style={{fontSize:'18px',fontweight:'bold'}}>{props.data.customer.name}</span> 
    </div>
  <div style={{marginBottom: '8px'}}>
   <b>Address</b>:<span >{props.data.customeraddress}</span>
    </div>
     <div style={{marginBottom:'8px'}}>
    <b>Contact</b>:<span>{props.data.customer.phone} </span>
    </div>

  </div>
  <div style={{border:'1px solid black',padding: '10px',width: '350px',borderRadius: '8px',height: '80px'}}>
    <div>
      <b>Salesman Name:</b><span >{props.data.salesman.name}</span> <br></br>
              <b>Salesman Phone</b><span >{props.data.salesman.phone}</span><br/>
              
    </div>
    <div >
     
              <b>Invoice Date</b><span >{props.data.manualdate}</span><br/>
             
    </div>
     <div >
     <b>Invoice</b>:<span >{props.data.invoiceno}</span> 
    </div>

    

     
  </div>
</div>

<div style={{paddingRight: '0px',paddingLeft: '0px',marginTop:'25px',border:'1px solid black',marginLeft: '0px',marginRight:'0px'}}>
     <table style={{width: '100%'}}>
        <thead >
              <tr>
                 <th  style={{textAlign: 'left',border: '1px solid #dddddd'}}>Sr#</th>
                        <th  style={{textAlign: 'left',border: '1px solid #dddddd'}}>Item Name</th>

                        <th  style={{textAlign: 'left',border: '1px solid #dddddd',display:'none'}}>Cotton</th>
                        <th  style={{textAlign: 'left',border: '1px solid #dddddd'}}>Qty</th>
                        <th  style={{textAlign: 'left',border: '1px solid #dddddd'}}>Unit</th>
                         <th  style={{textAlign: 'left',border: '1px solid #dddddd',display:'none'}}>Weight</th>

              
                          
                          <th  style={{textAlign: 'left',border: '1px solid #dddddd'}}>Rate</th>

                        <th   style={{textAlign: 'left',border: '1px solid #dddddd'}}>Sub Total</th>
                        <th  style={{textAlign: 'left',border: '1px solid #dddddd'}}>Discount</th>
                        <th  style={{textAlign: 'left',border: '1px solid #dddddd'}}> Total</th>
                      
                    </tr>

        </thead>

        <tbody id="printcartreciept">
        {props.data.letter && props.data.letter.map((item, index)=> (
        
        <tr key={index}>
         
                 <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{++index}</td>
                        <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{item.name}</td>

                        <td  style={{textAlign: 'left',border: '1px solid #dddddd',display:'none'}}>{(item.qty/item.pack_size).toFixed(2)}</td>
                        <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{item.qty}</td>
<td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{item.unit}</td>
                         <td  style={{textAlign: 'left',border: '1px solid #dddddd',display:'none'}}>{(item.qty*item.weight).toFixed(2)}</td>

              
                          
                          <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{item.unitsaleprice}</td>
                    
                        <td   style={{textAlign: 'left',border: '1px solid #dddddd'}}>{item.qty*item.unitsaleprice}</td>
                        <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{item.discountpercent}%
                      ({item.subtotaldiscount})</td>
                      <td   style={{textAlign: 'left',border: '1px solid #dddddd'}}>{parseFloat((item.qty*item.unitsaleprice)-(item.subtotaldiscount)).toFixed(2)}</td>

                    </tr>
))}


        </tbody>
      
<tfoot>
<tr >
                 <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}></td>
                        <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>Total</td>

                        <td  style={{textAlign: 'left',border: '1px solid #dddddd',display:'none'}}>{totalpack.toFixed(2)}</td>
                        <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{totalqty.toFixed(2)}</td>
                        <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}></td>
                         <td  style={{textAlign: 'left',border: '1px solid #dddddd',display:'none'}}>{totalweight.toFixed(2)}</td>

                          <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{totalrate.toFixed(2)}</td>
                          <td  style={{textAlign: 'left',border: '1px solid #dddddd',display:'none'}}></td>
                          <td  style={{textAlign: 'left',border: '1px solid #dddddd',display:'none'}}></td>

                        <td   style={{textAlign: 'left',border: '1px solid #dddddd'}}>{total.toFixed(2)}</td>
                        <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{subtotaldiscount.toFixed(2)}</td>
                        <td  style={{textAlign: 'left',border: '1px solid #dddddd'}}>{parseFloat(props.data.remainingbill).toFixed(2)}</td>
                        
                    </tr>

</tfoot>
    </table>


  </div>
  <div style={{marginLeft: '0px',marginRight: '0px',padding: '15px',display: 'flex',justifyContent: 'space-between',marginTop: '00px'}}>
      <div>
        
          <div style={{width: '350px',height: '50px',marginTop: '8px',borderRadius: '8px'}}>
            
<h2 style={{marginTop: '-0px'}}>Important Note!</h2>
<span  style={{marginTop: '-10px',fontFamily: 'Noto Nastaliq Urdu, cursive' }}>اپنا سامان موقع پر پورا کریں اور کیلکولیٹر کی کوئی واپسی نہیں ہو گی۔ اور سیلزمین کی ذاتی لین دین کی  کمپنی ذمہ دار نہ ہو گی <br/> </span>

          </div>
      </div>
          
        

          <div style={{width: '340px',height: '150px',padding: '20px',borderRadius: '10px'}}>
               
    <div style={{textAlign: 'left'}}>
      <b>Total Bill: <span >{parseFloat(props.data.grandtotal).toFixed(2)}</span></b>
 
    </div>
    <div style={{textAlign: 'left'}}>
      <b> Discount: <span >{parseFloat(props.data.discount).toFixed(2)}</span></b>
      
    </div>

     <div style={{textAlign: 'left'}}>
      <b> Remaning Bill: <span >{parseFloat(props.data.remainingbill).toFixed(2)}</span></b>
      
    </div>
    <div style={{textAlign: 'left',display:'none'}}>
      <b>Sales Tax: <span>{parseFloat(props.data.salestax).toFixed(2)}</span></b>
        
    </div>
    <div style={{textAlign: 'left',display:'none'}}>
      <b>Shipping: <span>{parseFloat(props.data.shipping).toFixed(2)}</span></b>
        
    </div>
    <div style={{textAlign: 'left'}}>
      <b>Pre Balance: <span>{parseFloat(props.data.pbalance).toFixed(2)}</span></b>
        
    </div>
     
     <div style={{textAlign: 'left'}}>
      <b>      Payment: <span >{parseFloat(props.data.recovery)+parseFloat(props.data.payment)}</span></b>

    </div>
   
    


      
     <div style={{textAlign: 'left'}}>
      <b> Curent Bills: <span style={{fontSize:'35px'}}>{parseFloat((parseFloat(props.data.grandtotal)-parseFloat(props.data.discount)+parseFloat(props.data.salestax)-(parseFloat(props.data.payment)+parseFloat(props.data.recovery))+parseFloat(props.data.pbalance)+parseFloat(props.data.shipping))).toFixed(2)}</span></b>
       
    </div>
          </div>
       </div>


  </div>

  
  
  )
        }
}

export default PrintSaleInvoice
