import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaEdit, FaPrint, FaTrash } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import PrintSaleInvoice from '../Store/PrintSaleInvoice';
import Puff from 'react-loading-icons/dist/esm/components/puff';


import Pusher from 'pusher-js';
import { Alert } from '@mui/material';
import { toast } from 'react-toastify';


function AllSaleInvoices() {

  const [invoiceList,setInvoiceList]=useState([]);  

  useEffect(() => {
    const pusher = new Pusher('43625e46da6558b51510', {
      cluster: 'ap2',
  });
  
    const channel = pusher.subscribe('sendInvoiceNoChenel');
    channel.bind('sendInvoiceNoEvent', (data) => {
      const object={
        'invoiceno':data.invoiceNo,
        'name':data.name
      }
  // setInvoiceList([...invoiceList,{...object}])
  setInvoiceList(prevInvoiceList => [...prevInvoiceList, object]);

   //setInvoiceList((prevInvoiceList) => [{...prevInvoiceList, data.invoiceNo,data.name}]);  
   
   // Handle the received data in your React component as needed
      
  });
    
   
    return () => {
      channel.unbind(); // Unbind all event listeners
      //console.log("awin chal gya");
      channel.unsubscribe(); // Unsubscribe from the channel
    };
  }, []);
 
const [invoices,setInvoices]=useState([]);
const [totalPages, setTotalPages] = useState(1);

const [searchQuery,setSearchQuery]=useState('');
const [currentPage, setCurrentPage] = useState(1);
const [printData, setPrintData] = useState([]);
const [isPrint,setIsPrint]=useState(false);
const [isClick,setIsClick]=useState('');
const [norecord,setNoRecord]=useState(false);


const handleSearchChange = (event) => {

    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleDelInvoice=(id,index)=>{
    setIsClick(true);
    
const data={

  'id':id
  }
  axios.post(`api/delete/invoice`,data).then(res=>{

    if(res.data.status===200){
      toast.error('Invoice Deleted!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      
      setIsClick(false);
   
    }else{
    
    
    }
    
    })


  }

useEffect(()=>{
    axios.get(`/api/allsaleinvoices?page=${currentPage}&search=${searchQuery}`).then(response=>{
      setInvoices(response.data.invoices.data);
      setTotalPages(response.data.invoices.last_page);
     
if((response.data.invoices.data).length===0){

  setNoRecord(true);
}else{
  setNoRecord(false);
}

    })
   
    
   
    


  
  },[currentPage,searchQuery,isClick]);



  

  
const handleReInvoice=(e,id,index)=>{
 
e.preventDefault();

setIsClick(index);
const data={
  'id':id
}

axios.get('/sanctum/csrf-cookie').then(response => {
axios.post('/api/reinvoice/print',data).then(res=>{
  
  const printDatas={
    letter:res.data.cart,
    dates:res.data.dates,
    salestax:res.data.salestax,
    shipping:res.data.shipping,
    discount:res.data.discount,
    grandtotal:res.data.grandtotal,
    invoiceno:res.data.invoiceno,
    remainingbill:res.data.remainingbill,
    customer:res.data.customer,
    customeraddress:res.data.customeraddress,
    salesman:res.data.salesman,
    payment:res.data.payment,
    totalpay:res.data.totalpay,
    pbalance:res.data.pbalance,
    recovery:res.data.recovery,
    printingtime:res.data.printingtime,
    manualdate:res.data.manualdate,
    detail:res.data.detail,
    imagesrc:res.data.detail
    
          }
          setPrintData(printDatas);
          
          
            setIsPrint(true);



          });
        });



}

useEffect(()=>{


  if(isPrint){

 
    var div = document.getElementById('printablearea');

    // Create a window object.
    var win = window.open('', '', 'height=700,width=700'); // Open the window. Its a popup window.
    win.document.write(div.outerHTML);     // Write contents in the new window.
    win.document.close();
    setTimeout(function() {
     // win.print();
      //win.close();
    }, 2000);
  setIsPrint(false);
  setIsClick('');
  }

},[isPrint]);


    return (
   <div className='animated fadeInDown' style={{overflowX:'auto'}}>
<center><h1>All Sale Invoices </h1><hr/></center>
<Row className='g-2'>
  <div className='col-md-4 mb-2'>
  <input type="text" className='form-control col-md' placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />

  </div>
<div className='col-md-3'>
{invoices.length? '':<Puff stroke='red'/>}  {norecord?'No Matchig Record Found':null}
</div>
 



  
    
</Row>

   <div className='row' >
    <div className='col-10 table-responsive'>

    <Table striped bordered hover size="sm" className='table  table-striped' >
    <thead>
        <tr>
        <th>Sr</th>
        <th>Customer Name</th>
        <th>Salesman Name</th>
        <th>Invoice No</th>
        <th>Total Amount</th>
        <th>Cash Recieved</th>
        <th>Discount</th>
        <th>Net Due</th>
        <th>Date</th>
        <th>Type</th>
    <th>Action</th>
        </tr>
    </thead>
  <tbody>

 {invoices && invoices.map((item,index)=>(


 <tr key={item.id}  style={{backgroundColor:item.qty<15?'lightpink':item.hold==='2'?'red':'white'}}
 >

<td>{index+1}</td>
<td>{item.name}{item.userid}</td>
<td>{item.salesman}{item.salesmanid}</td>
<td>{item.visitor_id}</td>
<td>{item.total}</td>
<td>{item.cash_received}</td>
<td>{item.Discount}</td>
<td>{item.due_amount}</td>
<td>{item.invoice_date}</td>
    <td>{item.type}</td>
 
<td>
    <button onClick={(e)=>handleDelInvoice(item.visitor_id,index)} className='btn btn-danger' style={{marginRight:'3px'}}>{isClick===index?<FaTrash style={{marginBottom:'3px'}}/>:<FaTrash  />} </button> 
    <Link target='_blank'  className='btn btn-success ml-3' to={`/editsaleinvoice/${item.visitor_id}`} ><FaEdit style={{marginBottom:'3px'}}/></Link> 
<button disabled={isClick===index?true:false} onClick={(e)=>handleReInvoice(e,item.visitor_id,index)} className='btn btn-primary' style={{marginLeft:'3px'}}>{isClick===index?<Puff width={15} height={10}/>:<FaPrint />} </button> 

</td>
       
      
       </tr>
      
 ))
    
}</tbody>  

</Table>
    </div>
    <div className='col-2' style={{overflowX:'hidden',overflowY:'auto',height:'200vh'}}>
<h5>FRESH INVOICE LIST</h5>
<hr></hr>

{invoiceList.slice().reverse() && invoiceList.slice().reverse().map((item,index)=>(

<div className='col-11 mt-1' key={item.invoiceno}>

<Alert
key={item.invoiceno}
>
  <p style={{cursor:'pointer'}} title={item.name}>
  {item.invoiceno}
  </p>
   
  <div style={{display:'flex',overflowX:'hidden'}}>
  <button title='Take Print' disabled={isClick===index?true:false} onClick={(e)=>handleReInvoice(e,item.invoiceno,index)} className='btn btn-primary' style={{marginRight:'4px',fontSize:'11px'}}>{isClick===index?<Puff width={15} height={10}/>:<FaPrint />} </button> 
 
  <Link title='Edit Invoice' style={{fontSize:'11px',marginLeft:'4px'}}  target='_blank'  className='btn btn-success ml-3' to={`/editsaleinvoice/${item.invoiceno}`} ><FaEdit style={{marginBottom:'1px'}}/></Link> 

  </div>
  
</Alert>

  </div>
  
  
  


  ))}



    </div>
    </div> 
    
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>

      <div style={{ display: 'none' }} >
{
  
  isPrint  && <PrintSaleInvoice data={printData} />
  
  
  }
  </div>

</div>



  
  )
}

export default AllSaleInvoices
