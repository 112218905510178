import React, {  useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import axios from 'axios';
function DailyBrandSale() {
    const [isClick,setIsClick]=useState(false);
    const [defaultsalesman,setDefaultSalesman]=useState('');
    const [defaultsector,setDefaultSector]=useState('');
    const [salesman,setSalesman]=useState([]);
    const [sector,setSector]=useState([]);
    const [manualdate,setManualDate]=useState('');
useEffect(()=>{
axios.get('/api/getsectors').then(res=>{

setSector(res.data.sectors);
setDefaultSector(res.data.sectors[0]['id']);

})


},[])
useEffect(()=>{
    axios.get('/api/getsalesman').then(res=>{
    
    setSalesman(res.data.salesman);
    setDefaultSalesman(res.data.salesman[0]['id']);
    
    })
    
    
    },[])
const handleFetchReport=()=>{
setIsClick(true);
const data={
'salesman_id':defaultsalesman,
'sector_id':defaultsector,
'manualdate':manualdate


}


axios({
    url: 'api/dailybrandsale',
    method: 'POST',
    responseType: 'blob', 
    data:data
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    window.open(url, '_blank');
    
   setIsClick(false);
  });


}
  return (
    <div className='row fadeInDown animated'>
      <center>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>Daily Brand Sale Report</h2> <hr/>
            </div>   
       
    <div className='col-md-10 mt-4'>
    <FloatingLabel controlId="manualdate" label="Enter Manual Date">
        <Form.Control type="date" value={manualdate} onChange={(e)=>setManualDate(e.target.value)} name="manualdate" placeholder="Enter Manual Date"  />
      </FloatingLabel>
     
    </div>

    <div className='col-md-10 mt-4'>
    <FloatingLabel  label="Select Sector Name ">
   
      <Form.Select aria-label="Sector Name"  value={defaultsector}  name='sector_id' onChange={(e)=>setDefaultSector(e.target.value)}>

      {sector && sector.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>

    <div className='col-md-10 mt-4'>
    <FloatingLabel  label="Select Salesman ">
   
      <Form.Select aria-label="Salesman Name" value={defaultsalesman}   name='salesman_id' onChange={(e)=>setDefaultSalesman(e.target.value)}>
       
      {salesman && salesman.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md-10 mt-4'>

    <Row className='g-2 mt-4'>

<div className='col md'>
<button onClick={handleFetchReport} className='btn btn-success btn-lg  btn-block'>{isClick?  <Puff/>:'Save Report'}  </button>

</div>

    </Row></div>
    </div></center>: 
    
    
    
    <center></center>
    </div>
  )
}

export default DailyBrandSale
