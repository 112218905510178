import React, { useEffect, useState } from 'react'
import { FaEdit, FaPrint, FaTrash } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import PrintPurchaseInvoice from '../StorePurchase/PrintPurchaseInvoice';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import axios from 'axios';

function AllPurchaseInvoices() {
    const [invoices,setInvoices]=useState([]);
const [totalPages, setTotalPages] = useState(1);

const [searchQuery,setSearchQuery]=useState('');
const [currentPage, setCurrentPage] = useState(1);

const [isClick,setIsClick]=useState('');
const [printData, setPrintData] = useState([]);
const [isPrint,setIsPrint]=useState(false);
const [norecord,setNoRecord]=useState(false);
const handleSearchChange = (event) => {

    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

useEffect(()=>{
    axios.get(`/api/allpurchaseinvoices?page=${currentPage}&search=${searchQuery}`).then(response=>{
      setInvoices(response.data.invoices.data);
     
      setTotalPages(response.data.invoices.last_page);

if((response.data.invoices.data).length===0){
  setNoRecord(true);
}else{

  setNoRecord(false);
}

    })
   
    
  
  },[currentPage,searchQuery]);

  const handleReInvoice=(e,id,index)=>{
 setIsClick(true);
    e.preventDefault();


    setIsClick(index);
    const data={
      'id':id
    }
   
    axios.get('/sanctum/csrf-cookie').then(response => {
    axios.post('/api/purchase/reinvoice/print',data).then(res=>{

      const printdatas={
        cart:res.data.cart,
        dates:res.data.dates,
        printingtime:res.data.printingtime,
        totalgst:res.data.salestax,
        totalwholesalemargin:res.data.wsmargin,
        totaldiscount:res.data.discount,
        totalretailmargin:res.data.totalretailmargin,
        totalwithgst:res.data.grandtotal,
        invoiceno:res.data.invoiceno,
        customer:res.data.customer,
        totalpaid:res.data.payment,
        pbalance:res.data.pbalance,
        lateststock:res.data.lateststock,
        latestvalue:res.data.latestvalue,
        detail:res.data.detail
        
        
        }
      
              
              setPrintData(printdatas);
              
              
                setIsPrint(true);
    
    
    
              });
            });


  }

  useEffect(()=>{


    if(isPrint){
  
   
      var div = document.getElementById('thermalpospurcahsecosmetics');
  
      // Create a window object.
      var win = window.open('', '', 'height=700,width=700'); // Open the window. Its a popup window.
      win.document.write(div.outerHTML);     // Write contents in the new window.
      win.document.close();
      
    setIsPrint(false);
    setIsClick('');
    }
  
  },[isPrint]);

  return (
    <div className='animated fadeInDown' style={{overflowX:'auto'}}>
    <center><h1>All Purchase Invoices</h1><hr/></center>
    <Row className='g-2'>
      <div className='col-md-4 mb-2'>
      <input type="text" className='form-control col-md' placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />
    
      </div>
    <div className='col-md-3'>
    {invoices.length? '':<Puff stroke='red'/>}  {norecord?'No Matchig Record Found':null} 
    </div>
     
    
    
    
      
        
    </Row>
    
        
        
        <Table striped bordered hover size="sm" >
        <thead>
            <tr>
            <th>Sr</th>
            <th>Customer Name</th>
           
            <th>Invoice No</th>
            <th>Total Amount</th>
            <th>Payment</th>
            <th>Discount</th>
            <th>Wholesale Margin</th>
            <th>Retail  Margin</th>
            <th>GST</th>
            <th>Net Due</th>
            <th>Date</th>
        <th>Action</th>
            </tr>
        </thead>
      <tbody>
       
    
     {invoices && invoices.map((item,index)=>(
    
    
     <tr key={item.id}  style={{backgroundColor:item.qty<15?'lightpink':item.hold==='2'?'red':'white'}}
     >
    
    <td>{index+1}</td>
    <td>{item.name}</td>
  
    <td>{item.visitor_id}</td>
    <td>{item.total}</td>
    <td>{item.payment}</td>
    <td>{item.discount}</td>
    <td>{item.wholesalemargin}</td>
    <td>{item.retailmargin}</td>
    <td>{item.gst}</td>
    <td>{parseFloat(item.due_amount).toFixed(2)}</td>
        <td>{item.invoice_date}</td>
     
    <td>
        <button className='btn btn-danger' style={{marginRight:'3px'}}><FaTrash style={{marginBottom:'3px'}}/></button> 
        <Link  className='btn btn-success ml-3' to={`/editpurchaseinvoice/${item.visitor_id}`} ><FaEdit style={{marginBottom:'3px'}}/></Link> 
    <button disabled={isClick===index?true:false} onClick={(e)=>handleReInvoice(e,item.visitor_id,index)} className='btn btn-primary' style={{marginLeft:'3px'}}>{isClick===index?<Puff width={15} height={10}/>:<FaPrint />} </button> 
    </td>
           
          
           </tr>
          
     ))
        
    }</tbody>  
    
    </Table>
     
    
       
    <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((page) => page - 1)}
    
            className='btn btn-danger btn-sm mr-3'
          >
            Previous
          </button>
          <span>{currentPage} of {totalPages}</span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((page) => page + 1)}
            className='btn btn-danger btn-sm'
          >
            Next 
          </button>
    
          <div style={{ display: 'none' }} >
    
          {
  
  isPrint  && <PrintPurchaseInvoice data={printData} />
  
  
  }

      </div>
    
    </div>
    
  )
}

export default AllPurchaseInvoices
