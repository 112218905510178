import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form, Row } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import { toast } from 'react-toastify';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import '../Customcss/table.css'
function ExpenseSheet() {
    const [salesman,setSalesman]=useState([]);
    const [sector,setSector]=useState([]);
    const [subsector,setSubSector]=useState([]);
    const [defaultsubsector,setDefaultSubSector]=useState(0);
    const [defaultsector,setDefaultSector]=useState(0);
    const [defaultsalesman,setDefaultSalesman]=useState(0);
    const [isClick,setIsClick]=useState(false);
    const [manualDate,setManualDate]=useState('');
    const [naration,setNaration]=useState('');
    const [amount,setAmount]=useState('');
    const [currentPage, setCurrentPage] = useState(1);
  
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [data,setData]=useState([]);
  const [indexit,setIndexIt]=useState('');
    

  
   const handleDelete=(e,id)=>{

    setIsClick(true);
   
     
   axios.delete(`api/delete/expense/${id}`).then(res=>{
   
   if(res.data.status===200){
    
    toast.error('Deleted Successfully!', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
   setIsClick(false);
   }else{
    toast.success('Something wentwrong !', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
   
   }
   
   })
   
   }
   const submitUpdate=(e)=>{
    setIsClick(true);
    
    e.preventDefault();
    const data={
      'userid':defaultsalesman,
      'manualdate':manualDate,
      'sector':defaultsector,
      'subsector':defaultsubsector,
      'naration':naration,
      'amount':amount,
      'id':editExpense
      }
    
    axios.get('/sanctum/csrf-cookie').then(res=>{
    axios.put('api/update/expense',data).then(res=>{
    if(res.data.status===200){
      toast.success('Updated Successfully!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      
      setIsClick(false);
    
    }else{
      setIsClick(false);
      toast.error('Something went wrong!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
     
    }
    
    
    })
    
    })
    
    }

   const [editExpense,setEditExpense]=useState('');
  const handleEdit=(e,id,index)=>{
    setIndexIt(index);
    setEditExpense(id);
    
    axios.get(`api/edit/expense/${id}`).then(res=>{
  
    setDefaultSector(res.data.expense.sector_id);
    setDefaultSubSector(res.data.expense.subsector_id);
    setDefaultSalesman(res.data.expense.user_id);
    setManualDate(res.data.expense.manual_date);
    setAmount(res.data.expense.expense_amount);
      setIndexIt('');
    })
    
    
    }
    useEffect(()=>{
      axios.get(`/api/view/expense?page=${currentPage}&search=${searchQuery}`).then(response=>{
        setData(response.data.expense.data);
        setTotalPages(response.data.expense.last_page);
      })
     
      
    
    },[currentPage,searchQuery,isClick]);
const handleSubmit=()=>{
setIsClick(true);
const data={
'userid':defaultsalesman,
'manualdate':manualDate,
'sector':defaultsector,
'subsector':defaultsubsector,
'naration':naration,
'amount':amount

}
if(data.amount===''||data.manualdate===''||data.subsector==='0'||data.userid==='0'){
  toast.error('Fill All Fields!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

    setIsClick(false);
    return false;
}




axios.post(`api/add/expense`,data).then(res=>{

  toast.success('Expense Added!', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });

    setIsClick(false);
})

}


    useEffect(()=>{
        axios.get('/sanctum/csrf-cookie').then(response => {
      axios.get(`/api/getsalesman`).then(res=>{
        setDefaultSalesman(res.data.salesman[0]['id']);
        setSalesman(res.data.salesman);
      
      });
      
     
      

      axios.get(`/api/getsectors`).then(res=>{
        setSector(res.data.sectors);
        setDefaultSector(res.data.sectors[0]['id']);
      
      });
     
      
        }); },[]);

        const handleSearchChange = (event) => {
          setSearchQuery(event.target.value);
          setCurrentPage(1);
        };
        useEffect(() => {
           
              
              axios.get(`/api/getsubsectors/${defaultsector}`)
              .then(response => {
                setSubSector(response.data.subsectors);
               // setDefaultSubSector(response.data.subsectors[0]['id']);
              })
              .catch(error => {
                console.error(error);
              });
           
           
          },[defaultsector]);

  return (
    <div>
    <div className='row fadeInDown animated'>
    <center>
     <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

     <div className='co-md-11'>
<h2>Expense Sheet</h2> <hr/>
         </div>   
    <Row className='g-2'>
 <div className='col-md mt-4'>

 <FloatingLabel  label="Enter Date" >
     <Form.Control type="date"  name="manualdate" placeholder='Enter Date'  value={manualDate} onChange={(e)=>setManualDate(e.target.value)} />
   </FloatingLabel>
 </div>
 <div className='col-md mt-4'>
 <FloatingLabel  label="Salesman Name">

   <Form.Select aria-label="Salesman Name" value={defaultsalesman} onChange={(e)=>setDefaultSalesman(e.target.value)}  name='salesman' >
     
   {salesman && salesman.map(item => (
   
<option key={item.id} value={item.id}>
 {item.name} </option>))}
   </Form.Select>
 </FloatingLabel>
 </div>
</Row>
<Row className='g-2'>
<div className='col-md mt-4'>

 <FloatingLabel  label="Sector Name">

   <Form.Select aria-label="Sector Name" value={defaultsector} onChange={(e)=>setDefaultSector(e.target.value)}  name='sector' >
    <option value={0}>Select Sector</option>
   {sector && sector.map(item => (
     
<option key={item.id} value={item.id}>
 {item.name} </option>))}
   </Form.Select>
 </FloatingLabel>
 </div>


<div className='col-md mt-4'>
 <FloatingLabel  label="Sub Sector Name">

   <Form.Select aria-label="Sector Name" value={defaultsubsector}  onChange={(e)=>setDefaultSubSector(e.target.value)}  name='sector' >
   <option value={0}>Select Sub Sector</option>
   {subsector && subsector.map(item => (
     
<option key={item.id} value={item.id}>
 {item.name} </option>))}
   </Form.Select>
 </FloatingLabel>
 </div>

</Row>
<Row className='g-2'>

<div className='col-md mt-4'>
<FloatingLabel  label="Enter Comments" >
    <Form.Control type="text"  name="naration" placeholder="Enter Comments" value={naration} onChange={(e)=>setNaration(e.target.value)} />
  </FloatingLabel>
 
</div>
<div className='col-md mt-4'>
<FloatingLabel  label="Enter Expense Amount" >
    <Form.Control type="text" required  name="amount" placeholder="Enter Expense Amount" value={amount} onChange={(e)=>setAmount(e.target.value)} />
  </FloatingLabel>
 
</div>



</Row>
 <div className='col-md-10 mt-4'>

 <Row className='g-2 mt-4'>


<div className='col-md-11'>
{editExpense===''?
<button  onClick={(e)=>handleSubmit(e)} className='btn btn-info btn-lg btn-block'>{isClick?  <Puff width={25} height={25} stroke="red"/>:'Add Expense'}  </button>
:
<button onClick={submitUpdate} className='btn btn-success btn-lg  btn-block'>{isClick?  <Puff height={25} width={30}/>:'Update'}  </button>
}
</div>

 </Row></div>
 </div></center>
 </div>
 <div className='container-fluid'  >
<div className='row' style={{padding:'30px'}}>

<div className='col-md-12' style={{ height: 'auto', overflow: 'auto',border:'1px solid lightgray  ',padding:'15px'}}>
<input type="text"  placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />
    
    <Table striped bordered hover size="sm" >
    <thead>
        <tr>
        <th>Sr</th>
        <th>Sector</th>
        <th>Subsector</th>
        <th>Salesman</th>

        <th>Date</th>
        <th>Amount</th>
        <th>Comment</th>
            <th>Update</th>
        </tr>
    </thead>
  <tbody>
 {data && data.map((item,index)=>(
 <tr key={item.id} 
 >

    <td>{index+1}</td>
    <td >{item.sector_name}</td>
    <td >{item.sub_sector_name}</td>
    <td >{item.user_name}</td>
    <td >{item.manual_date}</td>
    <td >{item.expense_amount}</td>
    <td >{item.naration}</td>
<td><button className='btn btn-danger' onClick={(e) => handleDelete(e,item.id)} style={{marginRight:'3px'}}><FaTrash style={{marginBottom:'3px'}}/></button> </td>
        
        <td>
        <button onClick={(e)=>handleEdit(e,item.id,index)} className='btn btn-success '> {indexit===index?<Puff height={10} width={12}/>: <FaEdit style={{marginBottom:'3px'}}/>}</button> 
        </td>
      
       </tr>
      
 ))
    
}</tbody>  

</Table>
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>
</div></div></div>

 </div>
  )
}

export default ExpenseSheet
